<script setup lang="ts">
import { OnClickOutside } from "@vueuse/components";
import { Ref, onMounted, ref } from "vue";

import Spinner from "@/components/Spinner.vue";
import ViewYacht from "@/pages/ViewYacht.vue";
import type { Yacht } from "@/types/yacht";

type Props = {
  accessToken: string;
};

const props = defineProps<Props>();

onMounted(async () => {
  try {
    // Start discovery - fetch available yachts
    hits.value = await fetchYachts(props.accessToken);
  } catch (e) {
    console.error(e);
  }
});

/**
 * Start global vars
 */
const hits: Ref = ref();

const loadingYacht: Ref<boolean> = ref(false);
const yacht: Ref<Yacht | null> = ref(null);
const isModalOpen: Ref<boolean> = ref(false);

const showToast: Ref<boolean> = ref(false);
const toastTimer: Ref = ref();
const toastSuccessMessage: Ref<string | null> = ref(null);
const toastUnsuccessfulMessage: Ref<string | null> = ref(null);

const isSearching: Ref<boolean> = ref(false);
const searchYachtName: Ref<string> = ref("");
const searchCharterType: Ref<string> = ref("");
const searchMinLength: Ref<string> = ref("");
const searchMaxLength: Ref<string> = ref("");
const searchRegion: Ref<string> = ref("");

/**
 * Begin a timed toast sequence that automatically clears after 15s
 */
const startToastSequence = () => {
  showToast.value = true;
  toastTimer.value = setTimeout(() => {
    showToast.value = false;
  }, 15000);
};

/**
 * Clears the toast from the page
 */
const clearToast = () => {
  clearTimeout(toastTimer.value);
  showToast.value = false;
  toastSuccessMessage.value = null;
  toastUnsuccessfulMessage.value = null;
};

/**
 * Fetch the yachts from the /website/search api. Can use specific yacht detail parameters in the search query
 * @param accessToken The users authorization access token retrieved from the previous api call
 * @param yachtName The yacht name
 * @param charterType Yacht charter type
 * @param minLength Yacht minimum length
 * @param maxLength Yacht maximum length
 * @param region Region where the yacht is serviced
 */
const fetchYachts = async (
  accessToken: string,
  yachtName?: string,
  charterType?: string,
  minLength?: string,
  maxLength?: string,
  region?: string
) => {
  let queryString = "?";
  // let queryString = "?minLength=0";
  // let queryString = "?region=East%20Mediterranean";
  if (yachtName) {
    queryString += `name=${yachtName}&`;
  }

  if (charterType) {
    queryString += `charterType=${charterType}&`;
  }

  if (minLength) {
    queryString += `minLength=${minLength}&`;
  }

  if (maxLength) {
    queryString += `maxLength=${maxLength}`;
  }

  if (region) {
    queryString += `region=${region}`;
  }

  if (queryString === "?") {
    queryString = "";
  } else {
    queryString = queryString.replace(/&$/, "");
  }

  try {
    const response = await fetch(`/website/search${queryString}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      const { hits } = await response.json();
      return hits;
    }

    toastUnsuccessfulMessage.value = await response.json();
    startToastSequence();
  } catch (e) {
    toastUnsuccessfulMessage.value = "Failed to get yacht hits.";
    startToastSequence();

    console.error(e);
    return Promise.resolve();
  }
};

/**
 * Searches for yachts with the /website/search api
 */
const searchYachts = async () => {
  try {
    isSearching.value = true;
    hits.value = await fetchYachts(
      props.accessToken,
      searchYachtName.value,
      searchCharterType.value,
      searchMinLength.value,
      searchMaxLength.value,
      searchRegion.value
    );
    isSearching.value = false;
  } catch (e) {
    isSearching.value = false;
    console.error(e);
  }
};

/**
 * Registers a yacht with the /website/register/{uri} api with POST
 * @param uri The yacht uri
 */

const registerYacht = async (uri: string) => {
  try {
    const response = await fetch(`/website/register/${uri}`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${props.accessToken}`,
      },
      body: JSON.stringify({ link: `https://ankor.io/vessels/${uri}` }),
    });

    if (response.status === 200) {
      toastSuccessMessage.value = "Successfuly registered yacht.";
    } else {
      toastUnsuccessfulMessage.value = await response.json();
    }

    startToastSequence();
  } catch (e) {
    toastUnsuccessfulMessage.value = "Failed to register yacht.";
    startToastSequence();

    console.error(e);
  }
};

/**
 * Deregisters a yacht with the /website/register/{uri} api with DELETE
 * @param uri The yacht uri
 */
const deRegisterYacht = async (uri: string) => {
  try {
    const response = await fetch(`/website/register/${uri}`, {
      method: "DELETE",
      headers: {
        authorization: `Bearer ${props.accessToken}`,
      },
    });

    if (response.status === 200) {
      toastSuccessMessage.value = "Successfuly deregistered yacht.";
    } else {
      toastUnsuccessfulMessage.value = await response.json();
    }

    startToastSequence();
  } catch (e) {
    toastUnsuccessfulMessage.value = "Failed to deregister yacht.";
    startToastSequence();

    console.error(e);
  }
};

/**
 * Retrieves more detailed info for the yacht with the /website/entity/{uri} api
 * @param uri The yacht uri
 */
const getYachtDetails = async (uri: string) => {
  loadingYacht.value = true;
  isModalOpen.value = true;
  clearToast();
  document
    .getElementsByTagName("html")
    .item(0)
    ?.classList.toggle("overflow-hidden");

  try {
    const response = await fetch(`/website/entity/${uri}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${props.accessToken}`,
      },
    });

    if (response.status === 200) {
      yacht.value = await response.json();
      loadingYacht.value = false;
    } else {
      yacht.value = null;
      loadingYacht.value = false;
      isModalOpen.value = false;
      document
        .getElementsByTagName("html")
        .item(0)
        ?.classList.toggle("overflow-hidden");

      toastUnsuccessfulMessage.value =
        "Failed to get yacht details. Kindly register before fetching details.";
      startToastSequence();
    }
  } catch (e) {
    yacht.value = null;
    loadingYacht.value = false;
    isModalOpen.value = false;
    document
      .getElementsByTagName("html")
      .item(0)
      ?.classList.toggle("overflow-hidden");

    toastUnsuccessfulMessage.value =
      "Failed to get yacht details. Kindly register before fetching details.";
    startToastSequence();

    console.error(e);
  }
};

/**
 * Closes the view yacht modal
 */
const closeModal = () => {
  isModalOpen.value = false;
  yacht.value = null;
  document
    .getElementsByTagName("html")
    .item(0)
    ?.classList.toggle("overflow-hidden");
};
</script>
<template>
  <div class="relative">
    <div v-if="!hits" class="h-screen flex justify-center">
      <Spinner />
    </div>
    <div
      v-else
      class="h-full max-w-screen-xl mx-auto p-4 md:p-8 overflow-hidden"
    >
      <!-- Search facets -->
      <div class="flex gap-2">
        <input
          type="text"
          id="yachtName"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Yacht name"
          v-model="searchYachtName"
        />

        <input
          type="text"
          id="charterType"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="CharterType Ex: Bareboat/Crewed"
          v-model="searchCharterType"
        />

        <input
          type="text"
          id="minLength"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Min length"
          v-model="searchMinLength"
        />

        <input
          type="text"
          id="maxLength"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Max length"
          v-model="searchMaxLength"
        />

        <input
          type="text"
          id="region"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Ex: Africa, Antarctica, Arabian Gulf, Australasia & South Pacific, Bahamas, Caribbean, Indian Ocean & South East Asia, North America, Northern Europe, East Mediterranean, West Mediterranean, South & Central America"
          v-model="searchRegion"
        />

        <button
          type="submit"
          class="flex justify-center items-center min-w-20 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm sm:w-auto py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          @click.stop="searchYachts"
        >
          <Spinner v-if="isSearching" class="w-5 h-5" />
          <span v-else>Submit</span>
        </button>
      </div>

      <!-- Search results -->
      <div class="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <div
          v-for="(hit, index) of hits"
          :key="`${index}:${hit.uri}`"
          class="rounded-lg shadow-md border bg-gray-200 dark:bg-gray-700 overflow-clip"
        >
          <img
            class="h-48 object-cover"
            :src="`${hit.hero.replace('{imageVariant}', '320w')}`"
          />
          <div
            class="h-[22rem] p-4 flex flex-col gap-y-2 rounded-b-lg bg-gray-100 dark:bg-gray-800"
          >
            <h1 class="font-bold text-xl text-black dark:text-white">
              {{ hit.name }}
            </h1>
            <div
              class="h-full flex flex-col justify-between gap-y-2 text-sm text-black dark:text-white"
            >
              <div>
                <p v-if="hit.length">
                  Company name: {{ hit.companyName.join(", ") }}
                </p>
                <p v-if="hit.length">Length: {{ hit.length }}m</p>
                <p v-if="hit.sleeps">Sleeps: {{ hit.sleeps }}</p>
                <p v-if="hit.make">Make: {{ hit.make }}</p>
                <p v-if="hit.cabins">Cabins: {{ hit.cabins }}</p>
                <p v-if="hit.builtYear">Built year: {{ hit.builtYear }}</p>
              </div>
              <div class="flex flex-col gap-y-1 text-xs">
                <button
                  type="button"
                  class="w-full rounded-lg transition-all px-3 py-2 border border-black dark:border-white text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                  @click="registerYacht(hit.uri)"
                >
                  Register
                </button>
                <button
                  type="button"
                  class="w-full rounded-lg transition-all px-3 py-2 border border-black dark:border-white text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                  @click="deRegisterYacht(hit.uri)"
                >
                  De-register
                </button>
                <button
                  type="button"
                  class="w-full rounded-lg transition-all px-3 py-2 border border-black dark:border-white text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                  @click="getYachtDetails(hit.uri)"
                >
                  Get entity
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Toasts for successful/unsucessful api request and confirmations -->
    <!-- Successful toast -->
    <div
      v-if="showToast && toastSuccessMessage"
      role="alert"
      id="toast-success"
      class="flex items-center w-full max-w-xs p-4 mb-4 fixed top-5 right-5 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
    >
      <div
        class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200"
      >
        <svg
          class="w-5 h-5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"
          />
        </svg>
        <span class="sr-only">Check icon</span>
      </div>
      <div class="ms-3 text-sm font-normal">{{ toastSuccessMessage }}</div>
      <button
        type="button"
        class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
        aria-label="Close"
        @click="clearToast()"
      >
        <span class="sr-only">Close</span>
        <svg
          class="w-3 h-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
      </button>
    </div>

    <!-- Unsuccessful toast -->
    <div
      v-if="showToast && toastUnsuccessfulMessage"
      id="toast-danger"
      class="flex items-center w-full max-w-xs p-4 mb-4 fixed top-5 right-5 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
      role="alert"
    >
      <div
        class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200"
      >
        <svg
          class="w-5 h-5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"
          />
        </svg>
        <span class="sr-only">Error icon</span>
      </div>
      <div class="ms-3 text-sm font-normal">{{ toastUnsuccessfulMessage }}</div>
      <button
        type="button"
        class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
        aria-label="Close"
        @click="clearToast()"
      >
        <span class="sr-only">Close</span>
        <svg
          class="w-3 h-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
      </button>
    </div>

    <!-- View yacht details -->
    <Teleport v-if="isModalOpen" to="#modal">
      <div
        class="fixed z-50 inset-0 backdrop-blur-[0.5rem] flex justify-center items-center p-2"
      >
        <OnClickOutside
          class="relative max-w-5xl max-h-[calc(100vh-5rem)] overflow-auto border sm:border-0 border-gray-200 dark:border-gray-600 rounded-lg shadow-md bg-white dark:bg-gray-900"
          tabindex="-1"
          aria-hidden="true"
          @trigger="closeModal()"
        >
          <div v-if="yacht" class="absolute right-4 top-3 flex justify-end">
            <div
              class="cursor-pointer p-1.5 w-6 h-6 self-center text-gray-500 dark:text-gray-400"
              @click="closeModal()"
            >
              &times;
            </div>
          </div>
          <ViewYacht :yacht="yacht" />
        </OnClickOutside>
      </div>
    </Teleport>
  </div>
</template>
