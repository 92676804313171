<script setup lang="ts">
import Accordion from "@/components/Accordion.vue";
import {
  type Blueprint,
  type InputAmountsTaxed,
  InputAmountsTaxedValues,
  type YachtPricing,
} from "@/types/yacht";

type Props = {
  pricing: YachtPricing;
  blueprint?: Blueprint;
};

const props = defineProps<Props>();

const currencyOptions = [
  { label: "USD", value: "&dollar;" },
  { label: "EUR", value: "&euro;" },
  { label: "GBP", value: "&pound;" },
  { label: "AUD", value: "&dollar;" },
  { label: "AED", value: "&#x62f;&#x2e;&#x625;" },
  { label: "SGD", value: "&dollar;" },
  { label: "HKD", value: "&dollar;" },
  { label: "BTC", value: "&#8383;" },
  { label: "ETH", value: "&#926" },
];

/**
 * A lesser used currency refers to a currency that the Intl.NumberFormat does not use
 * @param currency A currency value
 */
const isLesserUsedCurrencyCheck = (currency: string): boolean => {
  const lesserUsedCurrencies = ["AED", "SGD", "BTC", "ETH"];
  return lesserUsedCurrencies.includes(currency);
};

/**
 * Displays a given number to currency format
 * @param number A number value
 * @param currency A currency value
 */
const displayNumberInCurrency = (number: number, currency: string): string => {
  if (!number) {
    return "-";
  }

  if (!currency) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(number / 100);
  }

  if (isLesserUsedCurrencyCheck(currency)) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
      .format(number / 100)
      .substring(1);
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(number / 100);
};

/**
 * Displays a currency codes html entity
 * @param currency A currency value
 */
const displayCurrencyCode = (currency: string) => {
  if (!isLesserUsedCurrencyCheck(currency)) {
    return "";
  }

  const option = currencyOptions.filter((opt) => opt.label === currency)[0];
  return option.value;
};

const displayQuantity = (quantity: number) => {
  const suffix = quantity >= 1 ? "" : "%";
  const quantityToDisplay = suffix === "%" ? (quantity || 0) * 100 : quantity;
  return quantity
    ? `${Math.round(quantityToDisplay * 100) / 100}${suffix}`
    : "-";
};

const displayTaxOption = (option: InputAmountsTaxed) => {
  switch (option) {
    case InputAmountsTaxedValues.INCLUSIVE:
      return "Tax Inclusive";
    case InputAmountsTaxedValues.EXCLUSIVE:
      return "Tax Exclusive";
    case InputAmountsTaxedValues.NONE:
      return "No Tax";
  }
};
</script>
<template>
  <div
    v-for="(pricing, pricingIndex) of props.pricing.pricingInfo"
    class="mt-4 p-6 sm:p-8 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
    :key="`pricing-${pricingIndex}`"
  >
    <Accordion
      data-accordion="collapse"
      :id="`accordion-pricing-${pricingIndex}`"
    >
      <template #title>{{ pricing.name || pricingIndex + 1 }}</template>
      <template #content>
        <div class="flex flex-col gap-y-4">
          <!-- Label -->
          <div class="flex flex-col gap-y-2">
            <h5 class="text-gray-600 dark:text-gray-400 font-semibold text-sm">
              Label
            </h5>
            <p class="text-gray-500 text-xs whitespace-pre-wrap">
              {{ pricing.name }}
            </p>
          </div>

          <div
            class="grid grid-cols-3 gap-5 text-sm text-gray-900 dark:text-gray-300 font-normal"
          >
            <div>
              <div class="text-gray-500 font-semibold">Pets allowed</div>
              {{ pricing.petsAllowed ? "Yes" : "No" }}
            </div>
          </div>

          <hr class="h-px bg-gray-200 dark:bg-gray-700 border-0" />

          <!-- Pricing -->
          <div
            v-if="pricing.pricing?.lineItems.length"
            class="flex flex-col gap-y-2"
          >
            <h5 class="text-gray-600 dark:text-gray-400 font-semibold text-sm">
              Pricing
            </h5>

            <!-- Tax amounts -->
            <span
              class="text-sm text-right whitespace-nowrap text-black dark:text-white"
            >
              Amounts are
              {{ displayTaxOption(pricing.pricing.inputAmountTaxed) }}
            </span>

            <!-- Pricing table -->
            <div class="overflow-x-auto p-0.5">
              <table
                class="min-w-[49.5rem] w-full shadow rounded-lg overflow-hidden text-gray-500 dark:text-gray-400"
              >
                <thead
                  class="text-xs font-semibold text-left bg-gray-50 dark:bg-gray-700"
                >
                  <th class="uppercase py-4 px-2">ITEM</th>
                  <th class="uppercase py-4 px-2">QUANTITY</th>
                  <th class="uppercase py-4 px-2">PRICE</th>
                  <th class="uppercase py-4 px-2">AMOUNT</th>
                </thead>
                <tbody
                  v-for="(item, itemIndex) of pricing.pricing.lineItems"
                  :key="itemIndex"
                  class="text-sm"
                  :class="{
                    'border-t border-t-gray-200 dark:border-t-gray-700':
                      itemIndex !== 0,
                  }"
                >
                  <!-- Item -->
                  <td class="py-4 px-2">{{ item.item }}</td>
                  <!-- Quantity -->
                  <td class="font-semibold py-4 px-2">
                    {{ displayQuantity(item.quantity) }}
                  </td>
                  <!-- Price -->
                  <td class="font-semibold py-4 px-2">
                    <span
                      v-if="
                        pricing.pricing.currency &&
                        displayCurrencyCode(pricing.pricing.currency)
                      "
                      v-html="displayCurrencyCode(pricing.pricing.currency)"
                    ></span>
                    <span>{{
                      displayNumberInCurrency(
                        item.unitPrice,
                        pricing.pricing.currency
                      )
                    }}</span>
                  </td>
                  <!-- Amount -->
                  <td class="font-black py-4 px-2">
                    <span
                      v-if="
                        pricing.pricing.currency &&
                        displayCurrencyCode(pricing.pricing.currency)
                      "
                      v-html="displayCurrencyCode(pricing.pricing.currency)"
                    ></span>
                    <span>{{
                      displayNumberInCurrency(
                        item.amount,
                        pricing.pricing.currency
                      )
                    }}</span>
                  </td>
                </tbody>
              </table>
            </div>

            <!-- Pricing total -->
            <div class="flex justify-end">
              <div class="grid gap-x-4 gap-y-1 grid-cols-2 text-right text-sm">
                <template
                  v-if="
                    pricing.pricing.inputAmountTaxed !==
                    InputAmountsTaxedValues.NONE
                  "
                >
                  <span class="text-gray-400">Subtotal:</span>
                  <span class="font-semibold text-gray-500">
                    {{
                      pricing.pricing.subTotal
                        ? (pricing.pricing.subTotal / 100).toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )
                        : 0
                    }}
                    {{ pricing.pricing.currency }}
                  </span>
                </template>

                <span
                  v-if="
                    pricing.pricing.inputAmountTaxed ===
                    InputAmountsTaxedValues.INCLUSIVE
                  "
                  class="text-gray-400"
                >
                  Includes Tax:
                </span>
                <span
                  v-if="
                    pricing.pricing.inputAmountTaxed ===
                    InputAmountsTaxedValues.EXCLUSIVE
                  "
                  class="text-gray-400"
                >
                  Total Tax:
                </span>
                <span
                  class="font-semibold text-gray-500"
                  v-if="
                    pricing.pricing.inputAmountTaxed !==
                    InputAmountsTaxedValues.NONE
                  "
                >
                  {{
                    pricing.pricing.totalTax
                      ? (pricing.pricing.totalTax / 100).toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )
                      : 0
                  }}
                  {{ pricing.pricing.currency }}
                </span>

                <span class="text-gray-400">Total:</span>
                <span
                  class="font-semibold text-gray-500"
                  :class="{
                    'border-t': pricing.pricing.inputAmountTaxed !== 'NONE',
                  }"
                >
                  {{
                    pricing.pricing.total
                      ? (pricing.pricing.total / 100).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 0
                  }}
                  {{ pricing.pricing.currency }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Accordion>
  </div>
</template>
