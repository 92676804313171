<script setup lang="ts">
import Accordion from "@/components/Accordion.vue";
import PreviewPrice from "@/components/PreviewPrice.vue";
import Spinner from "@/components/Spinner.vue";
import type { Yacht } from "@/types/yacht";

type Props = {
  yacht: Yacht | null;
};

const props = defineProps<Props>();

const formatLength = (lengthInMeters: string | number): string => {
  const meters = Number(lengthInMeters);
  const feet = meters * 3.281;

  if (isNaN(meters) || isNaN(feet)) {
    return "";
  }

  return `${Math.floor(meters)}m/${Math.floor(feet)}ft`;
};

const intlCurrencyFormatter = (currency: string, price: number) => {
  if (!currency || !price) {
    throw new Error(
      "Currency or price was not supplied. Unable to format currency."
    );
  }

  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(price);
};
</script>
<template>
  <div
    class="h-full p-4 flex flex-col bg-white dark:bg-gray-800 sm:rounded-lg border-t border-b sm:border border-gray-200 dark:border-gray-600 shadow"
  >
    <div v-if="!props.yacht" class="h-full flex justify-center">
      <Spinner />
    </div>
    <template v-else>
      <h1
        class="text-center font-semibold text-xl tracking-widest text-gray-900 dark:text-white"
      >
        {{ props.yacht.blueprint.name }}
      </h1>

      <!-- Vessel assets -->
      <div class="mt-4 relative">
        <!-- Vessel images -->
        <div
          v-if="props.yacht.blueprint.images?.length"
          class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 my-4 gap-2 mx-auto"
        >
          <div
            v-for="(img, index) in props.yacht.blueprint.images.slice(1, 9)"
            :key="img"
            :class="{
              relative:
                index === 7 && props.yacht.blueprint.images.length - 9 > 0,
            }"
          >
            <img
              class="h-28 w-full rounded-lg object-cover"
              :src="`${img.replace('{imageVariant}', '320w')}`"
            />
            <div
              v-if="index === 7 && props.yacht.blueprint.images.length - 9 > 0"
              class="absolute bottom-2.5 right-2.5 w-fit rounded-md py-0.5 px-2.5 text-xs font-medium text-gray-900 bg-gray-50"
            >
              +{{ props.yacht.blueprint.images.length - 9 }} more
            </div>
          </div>
        </div>

        <!-- Vessel highlights -->
        <div
          class="p-5 rounded-lg bg-slate-100 dark:bg-slate-700 dark:bg-opacity-50 flex flex-col text-lg tracking-wider text-gray-900 dark:text-white"
        >
          <div class="flex flex-col items-center">
            Length: {{ formatLength(props.yacht.blueprint.length) }} Sleeps:
            {{ props.yacht.blueprint.sleeps }} Max Crew:
            {{ props.yacht.blueprint.maxCrew }}
          </div>

          <div class="text-center">
            Week Pricing:
            {{
              intlCurrencyFormatter(
                yacht?.pricing.weekPricingFrom.displayCurrency!,
                yacht?.pricing.weekPricingFrom.displayPrice!
              )
            }}
            &rarr;
            {{
              intlCurrencyFormatter(
                yacht?.pricing.weekPricingTo.displayCurrency!,
                yacht?.pricing.weekPricingTo.displayPrice!
              )
            }}
          </div>
        </div>
      </div>

      <!-- Yacht description -->
      <div
        class="mt-4 p-6 sm:p-8 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
      >
        <Accordion data-accordion="open" id="accordion-vessel-description">
          <template #title>Description</template>
          <template #content>
            <p
              class="text-sm text-gray-900 dark:text-gray-300 font-normal whitespace-pre-wrap"
            >
              {{ props.yacht.description }}
            </p>
          </template>
        </Accordion>
      </div>

      <!-- Vessel specifications -->
      <div
        class="mt-4 p-6 sm:p-8 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
      >
        <Accordion data-accordion="open" id="accordion-specifications">
          <template #title>Specifications</template>
          <template #content>
            <!-- Yacht Type & Crewed / Bareboat -->
            <div
              class="my-4 grid sm:grid-cols-2 gap-4 text-gray-900 dark:text-gray-50 font-medium text-sm"
            >
              <div class="flex">
                <label>Type:</label>
                <div v-if="props.yacht.yachtType?.length">
                  <span
                    v-for="yachtType in props.yacht.yachtType"
                    :key="yachtType"
                    class="mx-1 bg-gray-100 text-gray-900 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-white"
                  >
                    {{ yachtType }}
                  </span>
                </div>
                <span v-else>-</span>
              </div>
            </div>

            <hr class="h-px my-4 bg-gray-200 dark:bg-gray-700 border-0" />

            <div
              class="grid sm:grid-cols-3 gap-4 text-gray-900 dark:text-gray-50 font-medium text-sm"
            >
              <p>Sleeps: {{ props.yacht.blueprint.sleeps || "-" }}</p>
              <p>Crew: {{ props.yacht.blueprint.maxCrew || "-" }}</p>
              <p>Build Year: {{ props.yacht.blueprint.builtYear || "-" }}</p>
              <p>Model: {{ props.yacht.blueprint.model || "-" }}</p>
              <p>
                Top Speed (Kt/hr): {{ props.yacht.blueprint.topSpeed || "-" }}
              </p>
              <p>
                Cruising Speed (Kt/hr):
                {{ props.yacht.blueprint.cruiseSpeed || "-" }}
              </p>
              <p>
                Fuel Capacity (L):
                {{ props.yacht.blueprint.fuelCapacity || "-" }}
              </p>
              <p>
                Total no. of cabins: {{ props.yacht.blueprint.cabins || "-" }}
              </p>
              <p>Bathrooms: {{ props.yacht.blueprint.bathrooms || "-" }}</p>
              <p>Length: {{ `${props.yacht.blueprint.length}m` }}</p>
              <p>Beam Length: {{ `${props.yacht.blueprint.beam}m` || "-" }}</p>
              <p>
                Super Structure:
                {{ props.yacht.blueprint.superStructure?.join(", ") || "-" }}
              </p>
            </div>
          </template>
        </Accordion>
      </div>

      <!-- Vessel crew -->
      <div
        v-if="props.yacht.crew?.length"
        class="mt-4 p-6 sm:p-8 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
      >
        <Accordion data-accordion="collapse" id="accordion-crew">
          <template #title>Crew Members</template>
          <template #content>
            <div
              class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
            >
              <div
                v-for="(crew, index) of props.yacht.crew"
                class="flex flex-col bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 w-full mx-auto"
                :key="`${index}:${crew.name}`"
              >
                <img
                  v-if="crew.avatar"
                  class="rounded-t-lg object-cover h-72"
                  :src="`${crew.avatar.replace('{imageVariant}', '320w')}`"
                />
                <h1
                  v-if="crew.name"
                  class="text-2xl font-extrabold text-blue-600 dark:text-blue-700"
                >
                  {{ crew.name }}
                </h1>
              </div>
            </div>
          </template>
        </Accordion>
      </div>

      <!-- Yacht pricing -->
      <PreviewPrice
        v-if="props.yacht.pricing"
        :pricing="props.yacht.pricing"
        :blueprint="props.yacht.blueprint"
      />

      <!-- Toys -->
      <div
        v-if="props.yacht.blueprint?.toys?.length"
        class="mt-4 p-6 sm:p-8 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
      >
        <Accordion data-accordion="collapse" id="accordion-toys">
          <template #title>All Toys</template>
          <template #content>
            <div class="flex flex-col gap-y-4">
              <h6 class="text-gray-500 dark:text-gray-400 text-xs font-bold">
                Toys:
              </h6>
              <div
                class="flex flex-wrap gap-2 text-gray-700 dark:text-gray-200 font-medium text-xs"
              >
                <span
                  v-for="(toy, toyIndex) of props.yacht.blueprint?.toys"
                  class="min-w-[9.25rem] bg-gray-100 dark:bg-gray-700 text-xs font-medium px-4 py-2 rounded"
                  :key="`${toyIndex}-${toy.label}`"
                >
                  {{ toy.label }}
                  <span v-if="toy.quantity">x {{ toy.quantity }}</span>
                </span>
              </div>
            </div>
          </template>
        </Accordion>
      </div>

      <!-- Amenities -->
      <div
        v-if="props.yacht.blueprint?.amenities?.length"
        class="mt-4 p-6 sm:p-8 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
      >
        <Accordion data-accordion="collapse" id="accordion-toys">
          <template #title>All Amenities</template>
          <template #content>
            <div class="flex flex-col gap-y-4">
              <h6 class="text-gray-500 dark:text-gray-400 text-xs font-bold">
                Amenities:
              </h6>
              <div
                class="flex flex-wrap gap-2 text-gray-700 dark:text-gray-200 font-medium text-xs"
              >
                <span
                  v-for="(amenity, amenityIndex) of props.yacht.blueprint
                    ?.amenities"
                  class="min-w-[9.25rem] bg-gray-100 dark:bg-gray-700 text-xs font-medium px-4 py-2 rounded"
                  :key="`${amenityIndex}-${amenity.label}`"
                >
                  {{ amenity.label }}
                  <span v-if="amenity.quantity">x {{ amenity.quantity }}</span>
                </span>
              </div>
            </div>
          </template>
        </Accordion>
      </div>
    </template>
  </div>
</template>
