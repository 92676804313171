<script setup lang="ts">
import { initAccordions } from "flowbite";
import { onMounted } from "vue";

type Props = {
  id: string;
  dataAccordion: "collapse" | "open";
};

const props = defineProps<Props>();

onMounted(() => {
  initAccordions();
});
</script>
<template>
  <div class="w-full" :data-accordion="props.dataAccordion">
    <div
      data-test="accordion-container"
      class="cursor-pointer flex justify-between items-center bg-transparent"
      :aria-controls="props.id"
      :aria-expanded="true"
      :data-accordion-target="`#${props.id}`"
    >
      <h3
        data-test="title-heading"
        class="font-bold tracking-wider text-gray-700 dark:text-gray-300"
      >
        <slot name="title"></slot>
      </h3>
      <svg
        data-accordion-icon
        class="w-3 h-3 rotate-180 shrink-0"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 10 6"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M9 5 5 1 1 5"
        />
      </svg>
    </div>
    <div class="hidden" :id="props.id">
      <hr
        class="h-px my-4 bg-gray-200 dark:bg-gray-700 border-0"
        data-test="accordion-separator"
      />
      <slot name="content"></slot>
    </div>
  </div>
</template>
