/**
 * Main class representing a yacht.
 */
export declare type Yacht = {
  /**
   * The type of yacht that Ex. Gulet, Sailing, Catamaran, Motor, Expedition, Classic, Sport fishing
   */
  yachtType?: string[];
  /**
   * The URI of the yacht
   */
  uri: string;
  /**
   * Default description of the yacht
   */
  description: string;
  /**
   * The blueprint of the yacht (e.g., BoatBlueprint, CarBlueprint, JetBlueprint, etc.)
   */
  blueprint: Blueprint;
  /**
   * The crew members on the yacht
   */
  crew?: CrewMember[];
  /**
   * The pricing of the yacht
   */
  pricing: YachtPricing;
};

/**
 * Defines a Boat Blueprint
 */
export type Blueprint = {
  /**
   *The name of the boat
   */
  name: string;
  /**
   * Array of images paths
   */
  images?: string[];
  /**
   Length of the boat in meters
   */
  length: number;
  /**
   *   The number of bedrooms/cabins in the boat
   */
  cabins?: number;
  /**
   *   The number of people the boat can accommodate for sleeping
   */
  sleeps?: number;
  /**
   * Super yachts don't have a model since they are bespoke, however boats do
   */
  model?: string;
  /**
   * Bathrooms
   */
  bathrooms?: number;
  /**
   *  The year the boat was built/manufactured
   */
  builtYear?: number;
  /**
   * Max crew members
   */
  maxCrew?: number;
  /**
   *   The width of the boat at its widest point in meters
   */
  beam?: number;
  /**
   * The material of the superstructure of the boat
   */
  superStructure?: string[];
  /**
   *   The fuel capacity of the boat in liters
   */
  fuelCapacity?: number;
  /**
   *   The top speed of the boat in knots
   */
  topSpeed?: number;
  /**
   *   The cruising speed of the boat in knots
   */
  cruiseSpeed?: number;
  /**
   * Default amenities available on the boat
   */
  amenities?: Amenity[];
  /**
   * Default toys on the boat
   */
  toys?: Toy[];
};

export type Amenity = {
  /**
   * Name of the amenity on the yacht
   */
  label: string;
  /**
   * Quantity of the amenity on the yacht
   */
  quantity?: number;
};

export type Toy = {
  /**
   * Name of the toy on the yacht
   */
  label: string;
  /**
   * Quantity toy on the yacht
   */
  quantity?: number;
};

/**
 * Defines a member of the yacht crew
 */
export type CrewMember = {
  /**
   * The name of the crew member
   */
  name: string;
  /**
   * The avatar of the crew member
   */
  avatar?: string;
  /**
   * The role of the crew member
   * @deprecated - Will be deprecated in favor of roles
   */
  role?: string;
};

export type YachtPricing = {
  weekPricingFrom: WeekPricing;
  weekPricingTo: WeekPricing;
  pricingInfo: PricingInfo[];
};

export type WeekPricing = {
  currency: string;
  price: number;
  displayCurrency: string;
  displayPrice: number;
};

export type PricingInfo = {
  name: string;
  pricing: Pricing;
  inclusionZones: Zone[];
  exclusionZones: Zone[];
  petsAllowed: boolean;
};

export type Zone = {
  label: string;
};

export type Pricing = {
  /**
   * The currency of the pricing ISO-4217
   */
  currency: string;
  /**
   * The price unit for the pricing
   */
  unit: Unit;
  /**
   * Whether the items included are tax inclusive or exclusive
   */
  inputAmountTaxed: InputAmountsTaxed;
  /**
   * The line items for the pricing
   */
  lineItems: LineItem[];
  /**
   * subtotal of the pricing based on the inputAmountTaxed
   */
  subTotal?: number;
  /**
   * The total tax of all items
   */
  totalTax?: number;
  /**
   * The total price (damages) of all items including tax
   */
  total?: number;
};

/**
 * Defines the unit for pricing
 */
export type Unit = "WEEK" | "DAY" | "HOUR";

/**
 * Defines the enum tax values
 */
export enum InputAmountsTaxedValues {
  INCLUSIVE = "INCLUSIVE",
  EXCLUSIVE = "EXCLUSIVE",
  NONE = "NONE",
}

/**
 * Defines the amount type for pricing
 */
export type InputAmountsTaxed =
  | InputAmountsTaxedValues.INCLUSIVE
  | InputAmountsTaxedValues.EXCLUSIVE
  | InputAmountsTaxedValues.NONE;

/**
 * Defines a line item for pricing
 */
export type LineItem = {
  /**
   * The name of the line item
   */
  item: string;
  /**
   * The quantity of the line item
   */
  quantity: number;
  /**
   * The price of the line item
   */
  unitPrice: number;
  /**
   * The total amount of the line item
   */
  amount: number;
};
